import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/sass/index.scss';
import axios from 'axios';
import App from './App';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.clear();
      window.location.href = '/login/';
      return false;
    }
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
