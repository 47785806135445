import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

const User = lazy(() => import('../pages/User'));
const Action = lazy(() => import('../pages/Action'));
const HomePage = lazy(() => import('../pages/HomePage'));
const ActionLog = lazy(() => import('../pages/ActionLog'));
const Login = lazy(() => import('../pages/Login'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ForgotPasswordConfirm = lazy(() =>
  import('../pages/ForgotPasswordConfirm')
);
const UserProfil = lazy(() => import('../pages/UserProfil'));

function AppRoutes() {
  return (
    <Routes>
      <Route exact path='/login/' element={<Login />} />
      <Route
        exact
        path='/'
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path='/user/'
        element={
          <PrivateRoute>
            <User />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path='/action/'
        element={
          <PrivateRoute>
            <Action />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path='/action-log/'
        element={
          <PrivateRoute>
            <ActionLog />
          </PrivateRoute>
        }
      />
      <Route exact path='/forgot-password/' element={<ForgotPassword />} />
      <Route
        exact
        path='/password/reset/confirm/:uid/:token/'
        element={<ForgotPasswordConfirm />}
      />
      <Route
        exact
        path='/user/profil'
        element={
          <PrivateRoute>
            <UserProfil />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default AppRoutes;
