import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

function PrivateRoute({ children }) {
  const location = useLocation();
  if (
    !localStorage.getItem('userLogged') ||
    JSON.parse(localStorage.getItem('userLogged')) === false
  ) {
    return <Navigate to='/login/' state={{ from: location }} replace />;
  }
  return children;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
