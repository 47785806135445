import { QueryClient } from 'react-query';

const queryConfig = {
  queries: {
    retry: false,
    staleTime: 1000 * 60,
    useErrorBoundary: true,
  },
};

const queryClient = new QueryClient({ defaultOptions: queryConfig });

export default queryClient;
