/* eslint-disable import/prefer-default-export */
export const getCookie = (name) => {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(';');

  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i].split('=');

    /* Removing whitespace at the beginning of the cookie name
          and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
};

export const newDate = (date) => (date ? new Date(date) : '');

export const toFrDate = (date) =>
  date ? newDate(date).toLocaleDateString('fr-FR') : '';

export const toFrDateTime = (date) =>
  date ? newDate(date).toLocaleString('fr-FR') : '';

export const toFrDecimal = (decimal) =>
  decimal
    ? Number(decimal).toLocaleString('fr-FR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : '';
