import React, { Suspense } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';
import queryClient from './lib/react-query';
import { getCookie } from './lib/tools';
import AppWrapper from './lib/context';
import Notifications from './components/Notifications';

function App() {
  axios.defaults.headers.delete['X-CSRFToken'] = getCookie('csrftoken');
  axios.defaults.headers.patch['X-CSRFToken'] = getCookie('csrftoken');
  axios.defaults.headers.post['X-CSRFToken'] = getCookie('csrftoken');
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <Suspense fallback={<>...</>}>
          <AppWrapper>
            <Notifications />
            <AppRoutes />
          </AppWrapper>
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
