import React from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useAppContext } from '../lib/context';
import { ReactComponent as CheckIcon } from '../assets/img/svg/check.svg';
import { ReactComponent as CautionIcon } from '../assets/img/svg/caution.svg';

export default function Notifications() {
  const { notifications, setNotifications } = useAppContext();

  return (
    <ToastContainer position='top-end' className='p-3 notif'>
      <Toast
        show={notifications !== null}
        onClose={() => setNotifications(null)}
        delay={4000}
        autohide
      >
        <Toast.Header>
          {notifications?.icon === 'caution' && (
            <CautionIcon height='24' className='text-danger' />
          )}
          {notifications?.icon === 'success' && (
            <CheckIcon height='24' className='text-success' />
          )}
          <strong className='me-auto'>{notifications?.title}</strong>
        </Toast.Header>
        <Toast.Body>
          <ul className='list-unstyled'>
            {notifications?.messages.map((message) => (
              <li key={message}>{message}</li>
            ))}
          </ul>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
