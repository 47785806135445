import React, { createContext, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const AppContext = createContext([]);

export const useAppContext = () => useContext(AppContext);

export default function AppWrapper({ children }) {
  const [notifications, setNotifications] = useState(null);
  const providerValue = useMemo(
    () => ({ notifications, setNotifications }),
    [notifications, setNotifications]
  );
  return (
    <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>
  );
}

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
